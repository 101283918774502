import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchExchangeRate } from "../../reducers/exchangeRateReducer";
import Loader from "../../components/loader";
import { fetchUsers } from "../../reducers/userReducer";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { fetchSenderUserProfile } from "../../service/api";
import { analytics, errorAnalytics } from "../../helper/helper";
import { resetTransaction } from "../../reducers/transactionReducer";

export const CreateTransaction = () => {
  const [sourceAmount, setSourceAmount] = useState("");
  const [destinationAmount, setDestinationAmount] = useState("");
  const [destinationAmountInstant, setDestinationAmountInstant] = useState("");
  const [order, setOrder] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { exchangeRate, instantExchangeRate, loading } = useSelector((state) => state.exchangeRate);
  const user = useSelector((state) => state.userAuth);
  const [error, setError] = useState();
  const [instantLimitError, setInstantLimitError] = useState(false);
  const [minLimit, setMinLimit] = useState(1);
  const [maxLimit, setMaxLimit] = useState(0);
  const [maxInstant, setMaxInstant] = useState(0);
  const [purpose, setPurpose] = useState('');
  const [freeTextPurpose, setFreeTextPurpose] = useState('');
  const [value, setValue] = useState('option1');

  const handleChangeInstant = (event) => {
    setValue(event.target.value);
  };

  const selectPurposeOptions = [
    { name: 'Own account transfer', value: 'Own account transfer' },
    { name: 'Family maintainance', value: 'Family maintainance' },
    { name: 'Gift', value: 'Gift' },
    { name: 'Payment for services', value: 'Payment for services' },
    { name: 'Others', value: 'Others' },
  ];

  useEffect(() => {
    dispatch(fetchExchangeRate());
    fetchSenderUserProfile().then((r) => {
      setMinLimit(r.Per_transaction_min);
      setMaxLimit(r.Per_transaction_max);
      setMaxInstant(r.Instant);
    });
    dispatch(resetTransaction());
    // dispatch(
    //   fetchUsers({
    //     client_id: "APIGetBitRemitWeb",
    //     country_code: "+91",
    //     phone_number: id,
    //   })
    // );
  }, [dispatch, id]);

  const getFinalPurpose = (purpose) => {
    if (purpose === 'Others') return freeTextPurpose;
    return purpose;
  }

  const handleStartTransfer = () => {
    if (sourceAmount < minLimit) {
      setError(`Min Amount should be greater than $${minLimit}`);
      errorAnalytics("LIMIT_ERROR", {
        error: `Min limit error`,
        amount: sourceAmount,
      }); // Track limit error
    } else if (sourceAmount > maxLimit) {
      setError(`Max Amount should not be greater than $${maxLimit}`);
      errorAnalytics("LIMIT_ERROR", {
        error: `Max limit error`,
        amount: sourceAmount,
      }); // Track limit error
    } else if (!getFinalPurpose(purpose)) {
      setError(`Purpose is mandatory`);
      errorAnalytics("PURPOSE_NOT_ENTERED", {
        error: `Purpose is mandatory`
      }); // Track limit error
    } else {
      analytics.track("Transfer Started", { sourceAmount, destinationAmount }); // Track transfer start
      navigate(`/transaction/confirm/${id}/${sourceAmount}`, { state: { purpose: getFinalPurpose(purpose), is_instant: value == 'option1' ? false : true } });
    }
  };

  const handleSwitchAmount = () => {
    setOrder(order === 0 ? 1 : 0);
    analytics.track("Amount Input Switched", {
      sourceAmount,
      destinationAmount,
    }); // Track switching of amount inputs
  };

  const checkInstantError = (sourceAmount) => {
    if (sourceAmount > maxInstant) {
      instantLimitError != true && setInstantLimitError(true);
      setValue('option1')
    } else {
      instantLimitError == true && setInstantLimitError(false);
    }
  }

  const freePurposeTextBox = () => {
    if (purpose == 'Others') {
      return (
        <OutlinedInput
          placeholder="Please enter purpose..."
          value={freeTextPurpose}
          type="number"
          sx={{ marginTop: 2, width: "100%" }}
          multiline
          minRows={4}
          onChange={(e) => {
            setFreeTextPurpose(e.target.value);
          }}
          inputProps={{ maxLength: 100 }}
        />
      )
    }
  }

  return (
    <CroboContainer>
      <Container>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Typography textAlign={"center"} paddingTop={1}>
            Please note: Maximum transfer limit per transaction is $
            {maxLimit ? (
              maxLimit
            ) : (
              <CircularProgress size="14px" color="brand" />
            )}
          </Typography>
          <Typography variant="h5" paddingTop={2} textAlign={"center"}>
            Today's exchange rate
          </Typography>
          {loading && user.loading ? (
            <Loader />
          ) : (
            <Typography variant="h5" paddingTop={1} textAlign={"center"}>
              $1 = ₹{exchangeRate}
            </Typography>
          )}
        </Box>

        <Box
          display={"flex"}
          flexDirection={order === 0 ? "column" : "column-reverse"}
        >
          <OutlinedInput
            placeholder="Enter Amount"
            value={sourceAmount}
            startAdornment={
              <InputAdornment position="start">
                <img
                  src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                  height={30}
                  width={40}
                  alt="$"
                />
              </InputAdornment>
            }
            endAdornment={<InputAdornment position="end">USD</InputAdornment>}
            sx={{ marginTop: 3 }}
            type="number"
            inputProps={{ inputMode: 'numeric', onWheel: (e) => e.target.blur() }}
            onChange={(e) => {
              if (e.target.value < 0) return;
              setSourceAmount(e.target.value);
              setDestinationAmount((e.target.value * exchangeRate).toFixed(2));
              setDestinationAmountInstant((e.target.value * instantExchangeRate).toFixed(2));
              checkInstantError(e.target.value);
              analytics.track("Source Amount Entered", {
                sourceAmount: e.target.value,
              }); // Track source amount entered
            }}
            fullWidth
          />
          <Box justifyContent={"center"} display={"flex"}>
            <Button
              variant="contained"
              color="brand"
              sx={{
                borderRadius: "50%",
                width: 56,
                height: 56,
                minWidth: 0,
                marginTop: 3,
              }}
            >
              <SwapVertIcon sx={{ fontSize: "50px", color: "white" }} />
            </Button>
          </Box>

          <FormControl component="fieldset">
            <RadioGroup
              aria-label="options"
              name="radio-buttons-group"
              value={value}
              onChange={handleChangeInstant}
              sx={{ gap: 4 }}
            >
              {/* First option */}
              <Box>
                <FormControlLabel
                  value="option1"
                  control={<Radio />}
                  label={`Highest value (upto 48 business hours)`}
                />
                <OutlinedInput
                  placeholder="Enter Amount"
                  value={destinationAmount}
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                        height={30}
                        width={40}
                        alt="₹"
                      />
                    </InputAdornment>
                  }
                  endAdornment={<InputAdornment position="end">INR</InputAdornment>}
                  type="number"
                  onChange={(e) => {
                    if (e.target.value < 0) return;
                    setSourceAmount((e.target.value / exchangeRate).toFixed(2));
                    setDestinationAmount(e.target.value);
                    checkInstantError();
                  }}
                  inputProps={{ inputMode: 'numeric', onWheel: (e) => e.target.blur() }}
                  fullWidth
                  disabled={value !== 'option1'} // Disable this field if option1 is not selected
                  sx={{
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    '& fieldset': {
                      borderBottom: 'none',
                    },
                    ...(value !== 'option1' && { display: 'none' })
                  }}
                />
                <OutlinedInput
                  value={exchangeRate}
                  startAdornment={<InputAdornment position="start">Exchange Rate</InputAdornment>}
                  endAdornment={<InputAdornment position="end">USD/INR</InputAdornment>}
                  readOnly
                  fullWidth
                  disabled={value !== 'option1'} // Disable this field if option1 is not selected
                  sx={{
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    ...(value !== 'option1' && { display: 'none' })
                  }}
                />
              </Box>

              {/* Second option */}
              <Box>
                <FormControlLabel disabled={instantLimitError} value="option2" control={<Radio />} label="Instant (within seconds)" />
                <OutlinedInput
                  placeholder="Enter Amount"
                  value={destinationAmountInstant}
                  onChange={(e) => {
                    if (e.target.value < 0) return;
                    const sourceAmount = (e.target.value / instantExchangeRate).toFixed(2);
                    if (Number(sourceAmount) <= maxInstant) {
                      setSourceAmount(sourceAmount);
                      setDestinationAmountInstant(e.target.value);
                      checkInstantError(sourceAmount);
                    }
                  }}
                  inputProps={{ inputMode: 'numeric', onWheel: (e) => e.target.blur() }}
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                        height={30}
                        width={40}
                        alt="₹"
                      />
                    </InputAdornment>
                  }
                  endAdornment={<InputAdornment position="end">INR</InputAdornment>}
                  fullWidth
                  disabled={value !== 'option2'} // Disable this field if option2 is not selected
                  sx={{
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    '& fieldset': {
                      borderBottom: 'none',
                    },
                    ...(value !== 'option2' && { display: 'none' })
                  }}
                />
                <OutlinedInput
                  value={instantExchangeRate}
                  startAdornment={<InputAdornment position="start">Exchange Rate</InputAdornment>}
                  endAdornment={<InputAdornment position="end">USD/INR</InputAdornment>}
                  readOnly
                  fullWidth
                  disabled={value !== 'option2'} // Disable this field if option2 is not selected
                  sx={{
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    ...(value !== 'option2' && { display: 'none' })
                  }}
                />
                {instantLimitError ? <Typography fontSize={14} fontWeight={600}>Not eligible for this transactions as your instant transfer limit is ${maxInstant}</Typography> : null}
              </Box>
            </RadioGroup>
          </FormControl>

          <Select
            id="purpose"
            sx={{ marginTop: 4, width: "100%" }}
            value={purpose}
            displayEmpty
            onChange={(e) => {
              setPurpose(e.target.value);
              analytics.track("Purpose Selected", {
                purpose: e.target.value
              }); // Track purpose selection
            }}
            renderValue={(selected) => {
              if (selected === '') {
                return <>Select Purpose</>; // Placeholder text
              }
              return selected;
            }}
          >
            {selectPurposeOptions.map(option => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              );
            })}
          </Select>
          {freePurposeTextBox()}
        </Box>

        {error && (
          <Typography textAlign={"center"} color={"red"}>
            {error}
          </Typography>
        )}
        <Button
          color="brand"
          variant="contained"
          fullWidth
          size="large"
          sx={{ marginTop: 3 }}
          onClick={handleStartTransfer}
          disabled={
            !sourceAmount ||
            sourceAmount === 0 ||
            !destinationAmount ||
            destinationAmount === 0
          }
        >
          <Typography variant="h6" color={"black"}>
            Start Transfer
          </Typography>
        </Button>
      </Container>
    </CroboContainer>
  );
};
