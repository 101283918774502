// helpers/pollingHelper.js

export const startPolling = async (dispatch, action, token, n, loader, interval = 1000) => {

  const maxTime = 1 * 60 * 1000; // 30 minutes in milliseconds
  const startTime = Date.now();
  const maxApiCalls = 5;
  let apiCalls = 0;
  while ((Date.now() - startTime < maxTime) && apiCalls < maxApiCalls) {
    try {
      // Show loader
      // await dispatch(loader(true));

      // Wait for the interval before making the next dispatch
      await new Promise(resolve => setTimeout(resolve, interval));

      // Dispatch the action with the token
      await dispatch(action({ token }));
      apiCalls++;
    } catch (error) {
      console.error(`Polling error:`, error);
      // Handle error if necessary
    }
  }
  
  // Hide loader
  // await dispatch(loader(false));

  return false;
};
