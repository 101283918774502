import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../helper/helper";
import { backend } from "./userReducer";

// Define the async thunk
export const fetchExchangeRate = createAsyncThunk(
  "exchange/fetchExchangeRate",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${backend}/transaction/v2/exchange`,
        {
          source_currency: "USD",
          destination_currency: "INR",
          client_id: "APIGetBitRemitWeb",
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      );
      console.log(response.data)
      return response.data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

// Create the slice
const exchangeSlice = createSlice({
  name: "exchange",
  initialState: {
    exchangeRate: null,
    instantExchangeRate: null,
    status: "idle",
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchangeRate.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchExchangeRate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.exchangeRate = action.payload.exchange_rate;
        state.instantExchangeRate = action.payload.instant_exchange_rate;
      })
      .addCase(fetchExchangeRate.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default exchangeSlice.reducer;
