import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchExchangeRate } from "../../reducers/exchangeRateReducer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import { fetchUsers, remitStatusUpdate, remitStatusUpdateCall } from "../../reducers/userReducer";
import { amountRoundOff, analytics } from "../../helper/helper"; // Import analytics
import {
  resetTransaction,
  sendPayment,
} from "../../reducers/transactionReducer";

const isValidKycState = (bank, bankVerification, kyc) => {
  return bank === 1 && bankVerification === 1 && (kyc === 1 || kyc === 5);
}

const Confirm = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxWidth: "100%",
    bgcolor: "brandDark.main",
    color: "brandDark.text",
    border: "2px solid #000",
    p: 4,
  };

  const { id, amount } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { exchangeRate, loading, instantExchangeRate } = useSelector((state) => state.exchangeRate);
  const transaction = useSelector((state) => state.transaction);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.userAuth);
  const { bank, bankVerification, kyc } = user;
  const { fetchUser, bankDetails, token } = user;
  const location = useLocation();
  const { purpose, is_instant } = location.state || {}; // Get the passed state

  useEffect(() => {
    dispatch(resetTransaction());
    analytics.track("Transaction Reset"); // Track transaction reset
  }, [dispatch]);

  useEffect(() => {
    if (!isValidKycState(bank, bankVerification, kyc)) {
      // Invalid state
      const checkStatus = async () => {
        const remitStatusUpdateResponse = await remitStatusUpdateCall(token)
        if (!isValidKycState(remitStatusUpdateResponse.bank, remitStatusUpdateResponse.bank_verification, remitStatusUpdateResponse.kyc)) {
          navigate("/onboarding");
          analytics.track(
            "Redirected to Onboarding due to Invalid Bank/Verification/KYC"
          ); // Track redirection to onboarding
        }
      }
      checkStatus();
    }
  }, [dispatch, bank, bankVerification, kyc, navigate]);

  useEffect(() => {
    if (!id || !amount) {
      navigate("/transaction");
      analytics.track("Redirected to Transaction Due to Missing ID/Amount"); // Track missing ID or amount
    }

    if (transaction.isTransactionComplete) {
      navigate("/transaction/complete");
      analytics.track("Transaction Complete, Redirect to Complete Page"); // Track when transaction is complete
    }

    dispatch(
      fetchUsers({
        client_id: "APIGetBitRemitWeb",
        country_code: "+91",
        phone_number: id,
      })
    );
    dispatch(fetchExchangeRate());
    analytics.track("Exchange Rate Fetched"); // Track exchange rate fetched
  }, [dispatch, id, transaction.isTransactionComplete, amount, navigate]);

  const handleConfirm = () => {
    setOpen(true);
    analytics.track("Payment Confirmation Started", { amount, id }); // Track payment confirmation start
    dispatch(
      sendPayment({
        destinationCurrency: "INR",
        phoneNumber: id,
        sourceCurrency: "USD",
        amount: parseFloat(amount),
        purpose,
        is_instant
      })
    );
  };

  return (
    <CroboContainer>
      {loading ? (
        <Box py={3}>
          <Loader />
        </Box>
      ) : (
        <Container>
          <Card sx={{ boxShadow: "none" }}>
            <Box sx={{ paddingY: 1 }}>
              <Typography textAlign={"center"} variant="h6">
                You will send
              </Typography>
              <Typography textAlign={"center"} variant="h5">
                $ {amount}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ paddingY: 1 }}>
              <Typography textAlign={"center"} variant="h6">
                They will Receive:
              </Typography>
              <Typography textAlign={"center"} variant="h5">
                { is_instant ? amountRoundOff(instantExchangeRate * amount) : "₹" + amountRoundOff(exchangeRate * amount) }
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ paddingY: 1 }}>
              <Typography textAlign={"center"} variant="h6">
                Exchange Rate
              </Typography>
              <Typography textAlign={"center"} variant="h5">
                $1 = ₹{is_instant ? instantExchangeRate : exchangeRate}
              </Typography>
            </Box>
          </Card>

          <Box sx={{ paddingTop: 3 }}>
            {user.loading || loading ? (
              <Loader />
            ) : (
              <Box sx={{ minWidth: 275 }}>
                {bankDetails?.plaid_institution_name && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="debit-bank-details-content"
                      id="debit-bank-details-header"
                    >
                      <Typography sx={{ fontSize: 20, fontWeight: 900 }}>
                        Your Debit Bank Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid paddingY={1}>
                        <Grid
                          item
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          {bankDetails?.plaid_institution_name && (
                            <>
                              <Typography>Source bank</Typography>
                              <Typography>
                                {bankDetails.plaid_institution_name}
                              </Typography>
                            </>
                          )}
                        </Grid>
                        <Grid
                          item
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          {bankDetails?.plaid_account_mask && (
                            <>
                              <Typography>
                                Source bank account number
                              </Typography>
                              <Typography>
                                {bankDetails.plaid_account_mask}
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="recipient-details-content"
                    id="recipient-details-header"
                  >
                    <Typography sx={{ fontSize: 20, fontWeight: 900 }}>
                      Recipient Details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid paddingY={1}>
                      <Grid
                        item
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        {fetchUser?.name && (
                          <>
                            <Typography>Name</Typography>
                            <Typography>{fetchUser.name}</Typography>
                          </>
                        )}
                      </Grid>
                      <Grid
                        item
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        {fetchUser?.email && (
                          <>
                            <Typography>Email</Typography>
                            <Typography>{fetchUser?.email}</Typography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="bank-details-content"
                    id="bank-details-header"
                  >
                    <Typography sx={{ fontSize: 20, fontWeight: 900 }}>
                      Bank Details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      display={"flex"}
                      justifyContent={"space-between"}
                      paddingTop={1}
                    >
                      <Typography>Bank Name</Typography>
                      <Typography>
                        {fetchUser?.bankDetails?.bankName}
                      </Typography>
                    </Grid>
                    <Grid display={"flex"} justifyContent={"space-between"}>
                      <Typography>Account Number</Typography>
                      <Typography>
                        {fetchUser?.bankDetails?.bankAccountNumber}
                      </Typography>
                    </Grid>
                    <Grid display={"flex"} justifyContent={"space-between"}>
                      <Typography>IFSC code</Typography>
                      <Typography>{fetchUser?.bankDetails?.ifsc}</Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
          </Box>

          <Button
            color="brand"
            variant="contained"
            fullWidth
            onClick={handleConfirm}
            size="large"
            sx={{ marginTop: 3, mb: 5 }}
            disabled={loading || user.loading}
          >
            <Typography variant="h6" color={"black"}>
              Confirm
            </Typography>
          </Button>
        </Container>
      )}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Container>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              paddingBottom={1}
              textAlign={"center"}
              variant="h6"
              component="h2"
            >
              Please don't close the browser or tabs, Your transaction is under
              process
            </Typography>
            <Loader />
          </Box>
        </Container>
      </Modal>
    </CroboContainer>
  );
};

export default Confirm;
