import React, { useEffect } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TransferSvg from "../../assets/transfer_pending.svg";
import { useSelector } from "react-redux";
import { amountRoundOff, convertToHourMinute } from "../../helper/helper";

const TransactionComplete = () => {
  const user = useSelector((state) => state.userAuth);
  const transaction = useSelector((state) => state.transaction);
  const navigate = useNavigate();
  const { fetchUser } = user;
  useEffect(() => {
    if (!transaction.sendPayment) {
      navigate("/transaction");
    }
  }, [transaction.sendPayment, navigate]);

  const parseMessage = (error) => {
    switch (error) {
      case "Bank details not verified.":
        return "The transaction failed because the recipient's bank details are invalid, or you entered an NRE bank account.";
      default:
        return error;
    }
  };

  // Added Contact Support Button 
  return (
    <CroboContainer>
      <Container>
        <Card sx={{ boxShadow: "none" }}>
          <CardMedia
            sx={{
              p: 3,
              height: "100",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={TransferSvg} alt="waiting" height={"200px"} />
          </CardMedia>
          {transaction.sendPayment && transaction?.sendPayment?.message ? (
            <Box>
              <Typography textAlign={"center"} variant="h4">
                {parseMessage(transaction?.sendPayment?.message)}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography textAlign={"center"} variant="h4">
                Transfer Success
              </Typography>
              <Typography textAlign={"center"} variant="body2">
                You have sent money to {fetchUser.name}
              </Typography>
              <Typography
                sx={{ paddingY: 2 }}
                textAlign={"center"}
                variant="body2"
              >
                Total Amount
              </Typography>
              <Typography variant="h4" textAlign={"center"}>
                ${amountRoundOff(transaction?.sendPayment?.base_sub_total)}
              </Typography>
              <Divider />
              <Typography textAlign={"center"} sx={{ paddingY: 2 }}>
                Recipient
              </Typography>
              <Typography textAlign={"start"} variant="body2">
                {fetchUser.name} will receive
              </Typography>
              <Grid display={"flex"} justifyContent={"space-between"}>
                <Typography textAlign={"center"}>
                  ₹{amountRoundOff(transaction?.sendPayment?.base_total_amount)}
                </Typography>
                <Typography textAlign={"center"}>
                  {convertToHourMinute(
                    transaction?.sendPayment?.transactional_time
                  )}
                </Typography>
              </Grid>
            </Box>
          )}
        </Card>
        <Link to={`/transaction/history`}>
          <Button
            color="brand"
            variant="contained"
            fullWidth
            size="large"
            sx={{ marginTop: 3 }}
          >
            <Typography variant="h6" color={"black"}>
              Transaction History
            </Typography>
          </Button>
        </Link>
      </Container>
    </CroboContainer>
  );
};

export default TransactionComplete;
