import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { handleError } from "../helper/helper";
import { apiRoutes } from "./userReducer";
import axios from "axios";

const preRegisterSlice = createSlice({
  name: "preRegister",
  initialState: {
    preRegisterDetails: false,
    error: null,
    loading: false,
  },
  reducers: {
    resetPreregister: (state) => {
      state.preRegisterDetails = false;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(preRegister.fulfilled, (state, action) => {
        state.preRegisterDetails = action.payload;
        state.loading = false;
      })
      .addCase(preRegister.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(preRegister.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const callPreregister = async (params) => {
  const token = localStorage.getItem("token");
  let config = {
    method: "post",
    url: apiRoutes.preRegister,
    data: params,
    headers: { "x-auth-token": token },
  };
  const payload = await axios.request(config);
  return payload.data;
}

export const preRegister = createAsyncThunk(
  "preRegister",
  async (params, { rejectWithValue }) => {
    try {
      return await callPreregister(params);
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);
export const { resetPreregister } = preRegisterSlice.actions;
export default preRegisterSlice.reducer;
