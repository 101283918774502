import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import brandLogo from "../assets/crobo-white.png";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { apiRoutes, logout } from "../reducers/userReducer";
import { processName } from "../helper/helper";
import axios from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
const options = [
  { name: "Home", path: "/" },
  { name: "Start Transfer", path: "/transaction" },
  { name: "Profile", path: "/profile" },
  { name: "Transaction History", path: "/transaction/history" },
  { name: "About Us", path: "/about-us" },
  { name: "Refer & Earn", path: "/referrals" },
  { name: "Support", path: "/support" },
];

const hideProfile = ["/login", "/signup",'/privacy-policy','/tnc',];
const logoutPageList = ["/pin", "/onboarding", "/new", "/link/phone",'/condition', '/condition2'];
const nonProtectedRoute = ["/login", "/", "/signup", "/support", "/about-us",'/privacy-policy','/tnc','/link/phone'];

const Nav = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmallMobile = useMediaQuery("(max-width:400px)");
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = React.useState();
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      try {
        let config = {
          method: "get",
          url: apiRoutes.profile,
          headers: { "x-auth-token": token },
        };
        const payload = await axios.request(config);
        localStorage.setItem(
          "profile",
          JSON.stringify(payload.data.data.user_profile)
        );
        return payload;
      } catch (error) {
        if (error.response.status !== 200) {
          dispatch(logout());
          if (!nonProtectedRoute.includes(location.pathname)) {
            navigate("/login");
          }
        }
      }
    };

    checkAuth();
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (!nonProtectedRoute.includes(location.pathname)) {
      if (profile) {
        setUserName(processName(profile?.user_name)?.letters);
        // if (bank && bankVerification && kyc) {
        // } else {
        //   navigate("/onboarding");
        // }
      } else {
        dispatch(logout());
        navigate("/login");
      }
    }
    return () => {};
  }, [dispatch, navigate, location.pathname]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = (dispatch, navigate, handleCloseUserMenu) => {
    handleCloseUserMenu();
    dispatch(logout());
    navigate("/login");
  };

  const navigateBack = () => {
    if (
      location.pathname === "/transaction/complete" || location.pathname === "/transaction/history"
    ) {
      navigate("/transaction");
    } else {
      navigate(-1);
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: "0",
        width: "100%",
        bgcolor: "white",
        zIndex: 2,
        height: "60px",
      }}
    >
      <Grid display={"flex"} sx={{ position: "relative", height: "55px" }}>
        <Grid flexGrow={1}>
          {!nonProtectedRoute.includes(location.pathname) && (
            <IconButton onClick={navigateBack} sx={{ p: 1, zIndex: 1 }}>
              <Avatar sx={{ bgcolor: "grey.300" }}>
                <ArrowBackIosNewIcon sx={{ color: "black" }} />
              </Avatar>
            </IconButton>
          )}
        </Grid>

        <Grid
          container
          justifyContent={
            isMobile &&
            location.pathname === "/" &&
            !localStorage.getItem("profile")
              ? "flex-start"
              : "center"
          }
          alignContent={"center"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            background: "#fff",
            height: "60px",
            padding: "4px",
          }}
        >
          <Link to={"/"}>
            <img
              src={brandLogo}
              alt="brandLogo"
              height={isSmallMobile ? 33 : 50}
            />
          </Link>
        </Grid>
        {location.pathname === "/" && !localStorage.getItem("profile") ? (
          <Grid alignItems={"center"} display={"flex"} paddingY={1}>
            <Link to={"/login"} style={{ textDecoration: "none" }}>
              <Button id="sign-in-button" variant="outlined" color="brand">
                <Typography variant="body1" color={"black"}>
                  Login
                </Typography>
              </Button>
            </Link>
            <Link to={"/signup"} style={{ textDecoration: "none" }}>
              <Button
                id="sign-in-button"
                variant="contained"
                color="brand"
                sx={{ marginX: 1 }}
              >
                <Typography variant="body1" color={"black"}>
                  SignUp
                </Typography>
              </Button>
            </Link>
          </Grid>
        ) : (
          <Grid alignItems={"center"} display={"flex"} padding={1}>
            <Box sx={{ flexGrow: 0 }}>
              {!hideProfile.includes(location.pathname) && (
                <Tooltip title="Open profile">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar sx={{ bgcolor: "brand.main" }}>{userName}</Avatar>
                  </IconButton>
                </Tooltip>
              )}
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {!logoutPageList.includes(location.pathname) &&
                  options.map((o) => (
                    <Link
                      key={o.name}
                      to={`${o.path}`}
                      style={{ textDecoration: "none" }}
                    >
                      <MenuItem key={o.name} onClick={handleCloseUserMenu}>
                        <Typography
                          textAlign="center"
                          variant="h6"
                          color={"black"}
                          style={{ textDecoration: "none" }}
                        >
                          {o.name}
                        </Typography>
                      </MenuItem>
                    </Link>
                  ))}

                <MenuItem
                  key={"logout"}
                  onClick={() =>
                    handleLogout(dispatch, navigate, handleCloseUserMenu)
                  }
                >
                  <Typography
                    textAlign="center"
                    variant="h6"
                    color={"black"}
                    style={{ textDecoration: "none" }}
                  >
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Nav;
