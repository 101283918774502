import React, { useState, useEffect } from "react";
import {
  auth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  googleProvider,appleProvider,
  firebaseErrorHandler,
  PhoneAuthProvider,
  signInWithPopup,
} from "../service/firebase";
import {
  Container,
  Button,
  Box,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../reducers/userReducer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ErrorComponent from "../components/errorComponent";
import Loader from "../components/loader";
import { analytics } from "../helper/helper";
import CroboContainer from "../components/croboContainer";
import OTPInput from "react-otp-input";
import GoogleButton from "../components/googleButton";
import AppleButton from "../components/appleButton";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [cooldown, setCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { profile, pin, isNewUser } = useSelector((state) => state.userAuth);
  useEffect(() => {
    const handleProfileNavigation = () => {
      if (isNewUser) {
        navigate("/new");
      } else if (!pin) {
        navigate("/pin/set");
      } else {
        navigate("/pin");
      }
    };
    resetState();
    if (profile) {
      handleProfileNavigation();
    }
  }, [navigate, profile, pin, isNewUser]);

  useEffect(() => {
    initializeRecaptcha();
  }, []);

  useEffect(() => {
    const handleCooldownTimer = () => {
      let interval;
      if (cooldown) {
        interval = setInterval(() => {
          setCooldownTime((prevTime) => {
            if (prevTime <= 1) {
              clearInterval(interval);
              setCooldown(false);
              setIsResendDisabled(false);
              return 60;
            }
            return prevTime - 1;
          });
        }, 1000);
      }
      return () => clearInterval(interval);
    };
    handleCooldownTimer();
  }, [cooldown]);

  const resetState = () => {
    setPhone("");
    setOtp("");
    setOtpSent(false);
    setConfirmationResult(null);
    setError("");
  };

  const initializeRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      auth.appVerificationDisabledForTesting = true;
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {},
        }
      );
      window.recaptchaVerifier.render().catch((error) => {
        console.error("Recaptcha render failed:", error);
      });
    }
  };

  const handleSignInWithPhone = async () => {
    try {
      setLoader(true);
      analytics.track("OTP Requested", { phone });
      if (cooldown) {
        setError("Please wait a moment before retrying.");
        setLoader(false);
        return;
      }
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        `+1${phone}`,
        appVerifier
      );
      setConfirmationResult(confirmationResult);
      setOtpSent(true);
      setLoader(false);
      setIsResendDisabled(true);
      setCooldown(true);
      analytics.track("OTP Sent", { phone });
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    const errorMessage = firebaseErrorHandler(error.code);
    setError(errorMessage);
    setLoader(false);
    analytics.track("OTP Request Failed", { phone, error: errorMessage });
    if (error.code === "auth/too-many-requests") {
      setCooldown(true);
      setTimeout(() => setCooldown(false), 60000);
    }
  };

  const handleVerifyOtp = async () => {
    setLoader(true);
    analytics.track("OTP Verification Attempt", { phone, otp });
    if (confirmationResult) {
      try {
        const result = await confirmationResult.confirm(otp);
        analytics.identify(`+1${phone}`, {});
        dispatch(login({ phone, authToken: result.user.accessToken }));
        analytics.track("Login Success", { phone });
      } catch (error) {
        handleError(error);
      }
    }
  };

  const handleResendOtp = () => {
    analytics.track("OTP Resent", { phone });
    setIsResendDisabled(true);
    handleSignInWithPhone();
  };

  const handleOAuthSignIn = async (provider, method) => {
    try {
      setLoader(true);
      const result = await signInWithPopup(auth, provider);
      const user = auth.currentUser;
      const providerData = user.providerData;
      const isPhoneLinked = providerData.some(
        (provider) => provider.providerId === PhoneAuthProvider.PROVIDER_ID
      );
      console.log(isPhoneLinked);
      if (isPhoneLinked) {
        dispatch(login({ phone, authToken: result.user.accessToken }));
        result && analytics.track("Login Success", { method });
      } else {
        navigate("/link/phone");
      }
    } catch (error) {
      const errorMessage = firebaseErrorHandler(error.code);
      console.log(error);
      setError(errorMessage);
      setLoader(false);
      analytics.track(`${method} Sign-In Failed`, { error: errorMessage });
    }
  };

  const handleGoogleSignIn = () => handleOAuthSignIn(googleProvider, "Google");
  const handleAppleSignIn = () => handleOAuthSignIn(appleProvider, "Apple");

  const renderPhoneNumberInput = () => (
    <Box>
      <OutlinedInput
        placeholder="Phone Number"
        value={phone}
        type="number"
        disabled={otpSent}
        onChange={(e) => {
          const p = e.target.value;
          setPhone(p.toString());
          if (p.toString().length > 10) {
            setError("Invalid Phone Number");
          } else {
            setError(null);
          }
        }}
        startAdornment={
          <InputAdornment position="start">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
              height={30}
              width={40}
              alt="+1"
            />
            <Typography textAlign={"center"} paddingX={1}>
              (+1)
            </Typography>
          </InputAdornment>
        }
        fullWidth
        inputProps={{
          maxLength: 10,
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />
    </Box>
  );

  const renderOtpInput = () => (
    <Box paddingY={2}>
      <OTPInput
        value={otp}
        label="OTP"
        inputType="number"
        shouldAutoFocus="true"
        onChange={setOtp}
        numInputs={6}
        renderSeparator={<span style={{ margin: 5 }}></span>}
        renderInput={(props) => (
          <input
            {...props}
            style={{
              width: "100%",
              height: "40px",
              fontSize: 18,
              textAlign: "center",
              fontFamily: "Arial",
            }}
          />
        )}
      />
      <Box paddingY={2}>
        {loader ? (
          <Loader />
        ) : (
          <Button
            variant="contained"
            color="brand"
            onClick={handleVerifyOtp}
            fullWidth
            disabled={otp.length !== 6}
            margin="normal"
            size="large"
          >
            Verify OTP
          </Button>
        )}
      </Box>
      <Typography textAlign="center">
        {cooldown
          ? `Resend OTP in ${cooldownTime} seconds`
          : "Didn't receive the OTP?"}
      </Typography>
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          onClick={handleResendOtp}
          variant="text"
          disabled={isResendDisabled}
        >
          Resend OTP
        </Button>
      </Box>
    </Box>
  );

  const renderProceedButton = () => (
    <Box paddingY={2}>
      {loader ? (
        <Loader />
      ) : (
        <Button
          onClick={handleSignInWithPhone}
          id="sign-in-button"
          variant="contained"
          color="brand"
          fullWidth
          size="large"
          disabled={phone.length !== 10 || cooldown}
        >
          <Typography variant="h6">Proceed</Typography>
        </Button>
      )}
    </Box>
  );

  const renderTermsAndConditions = () => (
    <Grid marginTop={15}>
      {location.pathname === "/signup" && (
        <Typography variant="body2" textAlign={"center"}>
          By continuing, you agree to our{" "}
          <Link to={"/tnc"}>Terms of Service</Link> and <br /> confirm that you
          have read our <Link to={"/privacy-policy"}>Privacy Policy</Link> to
          learn <br /> how we collect, use, and share your data.
        </Typography>
      )}
    </Grid>
  );

  const renderFooterLinks = () => (
    <Box>
      {location.pathname === "/login" ? (
        <Typography textAlign={"center"} marginTop={4}>
          Don't have an account?
          <Link to={"/signup"}>
            <Button variant="text">Sign Up</Button>
          </Link>
        </Typography>
      ) : (
        <Typography textAlign={"center"} marginTop={4}>
          Already have an account?
          <Link to={"/login"}>
            <Button variant="text">Login</Button>
          </Link>
        </Typography>
      )}
    </Box>
  );

  const renderSocialButtons = () => {
    return (
      <Box paddingY={2}>
        {/* <GoogleButton onClick={handleGoogleSignIn} /> */}
        {/* <AppleButton onClick={handleAppleSignIn} /> */}
      </Box>
    );
  }

  return (
    <CroboContainer>
      <Container>
        <Box paddingTop={10} paddingBottom={4}>
          {location.pathname === "/login" ? (
            <>
              <Typography textAlign={"center"} variant="h5">
                Login to Crobo
              </Typography>
              <Typography textAlign={"center"} variant="body2">
                Login to your Crobo account to securely <br /> send money to
                your family in India.
              </Typography>
            </>
          ) : (
            <>
              <Typography textAlign={"center"} variant="h5">
                Sign Up for Crobo
              </Typography>
              <Typography textAlign={"center"} variant="body2">
                Create an account to securely <br /> send money to your family
                in India
              </Typography>
            </>
          )}
        </Box>

        {renderPhoneNumberInput()}
        {error && <ErrorComponent error={error} />}
        {!otpSent ? renderProceedButton() : renderOtpInput()}
        {/* {!otpSent && !loader && renderSocialButtons()} */}
        {renderTermsAndConditions()}
        {renderFooterLinks()}
      </Container>
    </CroboContainer>
  );
};

export default Login;
