// firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signInWithPopup,
  OAuthProvider,
  PhoneAuthProvider,
  signInWithPhoneNumber,
  AuthErrorCodes,
  linkWithCredential
} from "firebase/auth";
import { analytics } from "../helper/helper";

const firebaseConfig = {
  apiKey: "AIzaSyBJyH9WAXQyhu0OoKTpH1aMdTr76b1Y7a0",
  authDomain: "getbit-web-app-c4d11.firebaseapp.com",
  projectId: "getbit-web-app-c4d11",
  storageBucket: "getbit-web-app-c4d11.appspot.com",
  messagingSenderId: "802080839401",
  appId: "1:802080839401:web:19f770a034c21fb0a48278",
  measurementId: "G-CZQP2M1JB0",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.setCustomParameters({
  // Prompt the user to re-authenticate if they've already signed in with Apple.
  prompt: "login",
});

export const firebaseErrorHandler = (code) => {
  let errorMessage = "";
  switch (code) {
    case "auth/invalid-phone-number":
      errorMessage = "The phone number is not valid.";
      break;
    case "auth/missing-phone-number":
      errorMessage = "The phone number is missing.";
      break;
    case "auth/quota-exceeded":
      errorMessage = "SMS quota exceeded. Try again later.";
      break;
    case "auth/user-disabled":
      errorMessage =
        "The user corresponding to the given phone number has been disabled.";
      break;
    case "auth/operation-not-allowed":
      errorMessage = "Phone number sign-in is not enabled.";
      break;
    case "auth/too-many-requests":
      errorMessage = "Too many requests. Please try again later.";
      break;
    case "auth/app-not-authorized":
      errorMessage =
        "This app is not authorized to use Firebase Authentication.";
      break;
    case "auth/captcha-check-failed":
      errorMessage = "The reCAPTCHA verification failed. Try again.";
      break;
    case "auth/network-request-failed":
      errorMessage = "Network error. Please try again.";
      break;
    case "auth/invalid-verification-code":
      errorMessage = "The verification code is not valid.";
      break;
    case "auth/missing-verification-code":
      errorMessage = "The verification code is missing.";
      break;
    case "auth/code-expired":
      errorMessage = "The verification code has expired. Please request a new code.";
      break;
    case "auth/credential-already-in-use":
      errorMessage = "This credential is already associated with a different user account.";
      break;
    case "auth/invalid-credential":
      errorMessage = "The supplied auth credential is malformed or has expired.";
      break;
    default:
      errorMessage = "An unknown error occurred. Please try again.";
  }
  analytics.track(`ERROR ${errorMessage}`, {
    code,
  });
  return errorMessage;
};
export {
  auth,
  getAuth,
  googleProvider,
  RecaptchaVerifier,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  appleProvider,
  signInWithPopup,
  signInWithPhoneNumber,
  AuthErrorCodes,
  linkWithCredential,
  PhoneAuthProvider
};
