import React from "react";
import { Box, Typography, Link, Button, IconButton } from "@mui/material";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import EmailIcon from "@mui/icons-material/Email";
import brandLogo from "../assets/Crobo_Full.png";
import { analytics, handleOpenChat } from "../helper/helper";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import APP_STORE_ICON from "../assets/appStore.svg";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{ backgroundColor: "#36454F", padding: 5, mt: 5, borderTop: 1 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", py: 3 }}>
          <img src={brandLogo} alt="CROBO Logo" width="200px" />
          <Typography variant="h6" color="white" fontWeight="semi-bold">
            Available on
          </Typography>
          <Box>
            <Button
              variant="contained"
              sx={{
                my: 1,
                marginInlineEnd: 2,
                border: "solid 1px white",
                backgroundColor: "transparent",
              }}
              alignItems="center"
              href="https://apps.apple.com/us/app/crobo/id6474628320"
              startIcon={
                <img src={APP_STORE_ICON} alt="APP STORE ICON" height="40px" />
              }
              onClick={() => {
                analytics.track("App Store Button Clicked", {
                  category: "Engagement",
                  label: "App Store Link",
                  url: "https://apps.apple.com/us/app/crobo/id6474628320",
                });
              }}
            >
              <Typography color="white">IOS app</Typography>
            </Button>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfoIcon sx={{ color: "white" }} />
            <Link
              sx={{ color: "white", p: 0, px: 1 }}
              href="/about-us"
              color="white"
              underline="none"
            >
              <Typography variant="h6">About Us</Typography>
            </Link>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", py: 3 }}>
          <Typography variant="h6" color="white" fontWeight="bold">
            Socials
          </Typography>
          <Box display="flex" py={0}>
            <IconButton
              component="a"
              href="https://www.linkedin.com/company/crobo-money"
              target="_blank"
              aria-label="LinkedIn"
            >
              <LinkedInIcon sx={{ fontSize: "40px", color: "white" }} />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.instagram.com/crobo_money"
              target="_blank"
              aria-label="Instagram"
            >
              <InstagramIcon sx={{ fontSize: "40px", color: "white" }} />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.twitter.com/crobo_money"
              target="_blank"
              aria-label="Twitter"
            >
              <TwitterIcon sx={{ fontSize: "40px", color: "white" }} />
            </IconButton>
            {/* <IconButton
              component="a"
              onClick={openWhatsApp}
              aria-label="WhatsApp"
            >
              <WhatsAppIcon sx={{ fontSize: "40px", color: "black" }} />
            </IconButton> */}
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", py: 3 }}>
          <Typography variant="h6" color="white" fontWeight="bold">
            Customer Care
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <HeadsetMicIcon fontSize="small" sx={{ color: "white" }} />

              <Button
                sx={{ color: "white", p: 0, px: 1 }}
                onClick={handleOpenChat}
              >
                Contact Support
              </Button>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EmailIcon fontSize="small" sx={{ color: "white" }} />
              <Link
                sx={{ color: "white", p: 0, px: 1 }}
                href="mailto:care@crobo.money"
                color="white"
                underline="none"
              >
                <Typography>care@crobo.money</Typography>
              </Link>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <HelpIcon fontSize="small" sx={{ color: "white" }} />
              <Link
                sx={{ color: "white", p: 0, px: 1 }}
                href="/support"
                color="white"
                underline="none"
              >
                <Typography>Support</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
