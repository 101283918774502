import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Container,
    Typography,
} from "@mui/material";
import React from "react";
import { ToastContainer } from 'react-toastify';
import { analytics, processName } from "../helper/helper";
import { useNavigate } from 'react-router-dom';

const FrequentContactCard = ({
    id,
    name,
    phone,
    email
}) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                p: 2,
                boxShadow: 1,
                borderRadius: 4,
                my: 0.5,
                bgcolor: "grey.200",
                cursor: "default",
                "&:hover": {
                    boxShadow: 5,
                },
                display: 'flex',
                width: '100%'
            }}
            onClick={() => {
                navigate(`/transaction/create/${phone}`);
                analytics.track("Frequent Contact Selected", {
                    name,
                    phone,
                })
            }}
        >
            <ButtonBase>
                <Avatar
                    sx={{
                        bgcolor: "brand.main",
                        width: 40,
                        height: 40,
                        fontSize: 18,
                        marginX: 2,
                        marginY: 1,
                    }}
                >
                    {processName(name).letters}
                </Avatar>
            </ButtonBase>
            <Typography textAlign={"center"} color={"black"} sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                {processName(name).words}
            </Typography>
            <ToastContainer
                hideProgressBar
                position="top-center"
            />
        </Box>
    );
};

export default FrequentContactCard;
